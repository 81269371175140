import { Spinner, Text, Grid, Stack, Input, Center, InputGroup, 
    InputRightElement, Box, Popover, PopoverTrigger, 
    PopoverContent, PopoverBody,GridItem} from "@chakra-ui/react"
import EventBox from "../Components/EventBox";
import { getEvents, getProductType } from "../apis/library.api";
import { useEffect, useState } from "react";
import CloseIcon from "../assets/images/icons/Close filter.svg";
import SearchIcon from "../assets/images/icons/Search icon.svg";
import ArrowIcon from "../assets/images/icons/Icon-Medium-Grey-Arrow-right.svg";
import { useMediaQuery } from "react-responsive";
import { LibraryProductTypes } from "../assets/constants";

// All event data array.
var allEventData = [];

export default function Content(productType = null) {

    const [eventData, setEventData] = useState(allEventData);
    const [isLoading, setIsLoading] = useState(true);
    const [pageLength, setPageLength] = useState(8);
    const [showSmallPageFilters, setShowSmallPageFilters] = useState(false);
    const [pages, setPages] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    
    // Filter menu state values
    const [locationStateValue, setLocationStateValue] = useState('');
    const [fromStateValue, setFromStateValue] = useState('');
    const [toStateValue, setToStateValue] = useState('');
    const [searchStateValue, setSearchStateValue] = useState('');

    // Define size for tablets or smaller
    const isTabletOrSmaller = useMediaQuery({
        query: "(max-width: 800px)"
    });
    
    const productTypeValue = productType;

    useEffect(() => {
        const runGetEvents = async () => {
            const response = await getEvents(productTypeValue);
            
            for(var item in response) {
                // Check events are future or today and add to the all event list.
                var dateCheck = new Date();
                dateCheck.setDate(dateCheck.getDate() - 1);

                if (response[item].date >= dateCheck) {   
                    
                    // Set Product Type for each event
                    var productTypes = [];
                    
                    // Loop through product types and get product names from API
                    for (const index in response[item].productTypeId) {
                        var productType = await getProductType(response[item].productTypeId[index]);
                        if (LibraryProductTypes.indexOf(productType) > -1) {
                            productTypes.push(productType);
                        }
                    }

                    response[item].productTypeName = productTypes;
                    
                    allEventData.push(response[item]);
                }
            }

            // Sort the even data from most recent first
            allEventData = [].concat(allEventData)
                .sort((a, b) => a.date > b.date ? 1 : -1);

            CreatePagination(Math.ceil(allEventData.length / 8));
            setEventData(allEventData);
            setIsLoading(false);
        }
        

        // Run Events or stop loading
        if (allEventData.length === 0) {
            runGetEvents();
        } else {
            if (isLoading === true) {
                setIsLoading(false);
            }
        }

    // eslint-disable-next-line
    }, []);

    function CreatePagination(pageLength) {
        var pageArray = [];
        for (let i = 0; i < pageLength; i++) {
            pageArray.push(i + 1);
        }
        setPages(pageArray);
    }

    // Filter everything when selected
    function PageFilters() {
        let fromValue: any = (document.getElementById('fromDate') as HTMLInputElement).value;
        let toValue: any = (document.getElementById('toDate') as HTMLInputElement).value;   
        let searchValue: any = (document.getElementById('search') as HTMLInputElement).value;   
        let locationValue: any = (document.getElementById('location') as HTMLInputElement).value;   

        if (fromValue) {            
            setFromStateValue(fromValue);
            fromValue = new Date(fromValue);
            fromValue = fromValue.setHours(0,0,0,0);
            fromValue = new Date(fromValue);
        }

        if (toValue) {            
            setToStateValue(toValue);
            toValue = new Date(toValue);
            toValue = toValue.setHours(23,59,59,0);
            toValue = new Date(toValue);
        }

        var filters = { 
            fromFilter: item => item.date >= fromValue,
            toFilter: item => item.date <= toValue,
            searchValue: item => item.title.toLowerCase().includes(searchValue.toLowerCase()),
            locationValue: item => item.city === locationValue
        }

        var selectedFilters = [];

        if (fromValue) {
            selectedFilters.push(filters.fromFilter);
        }

        if (toValue) {
            selectedFilters.push(filters.toFilter);
        }

        if (searchValue) {
            selectedFilters.push(filters.searchValue);
            setSearchStateValue(searchValue);
        }
        
        if (locationValue) {
            selectedFilters.push(filters.locationValue);
            setLocationStateValue(locationValue);
        }

        setPageLength(8);
        setEventData(allEventData.filter(item => selectedFilters.every(f => f(item))));
    }

    // Clear all filters
    function ClearFilters() {
        var fromValue: any = (document.getElementById('fromDate') as HTMLInputElement);
        var toValue: any = (document.getElementById('toDate') as HTMLInputElement);
        let searchValue: any = (document.getElementById('search') as HTMLInputElement);   
        let locationValue: any = (document.getElementById('location') as HTMLInputElement); 

        fromValue.value = "";
        toValue.value = "";
        searchValue.value = "";
        locationValue.value = "";

        setFromStateValue("");
        setToStateValue("");
        setLocationStateValue("");
        setSearchStateValue("");

        PageFilters();
    }

    function incrementPage() {
        // Get all necessary elements
        var currentPage = document.getElementById(`page-index-${pageIndex}`) as HTMLInputElement;
        var selectedPage = document.getElementById(`page-index-${pageIndex + 1}`) as HTMLInputElement;
        
        // Manipulate elements
        currentPage.classList.remove("page-index-active");
        selectedPage.classList.add("page-index-active");

        setPageLength(pageLength + 8)
        setPageIndex(pageIndex + 1);
    }

    function decrementPage() {
        // Get all necessary elements
        var currentPage = document.getElementById(`page-index-${pageIndex}`) as HTMLInputElement;
        var selectedPage = document.getElementById(`page-index-${pageIndex - 1}`) as HTMLInputElement;

        // Manipulate elements          
        currentPage.classList.remove("page-index-active");
        selectedPage.classList.add("page-index-active");

        setPageLength(pageLength - 8)
        setPageIndex(pageIndex - 1);
    }

    return (
        <>
            {isLoading && <>
                <Text>Loading... <Spinner /></Text>
            </>}
            {!isLoading &&
            <>
                {/* FILTER MENU */}
                {isTabletOrSmaller && 
                    <>
                        <button className="filter-menu-show-button" onClick={() => setShowSmallPageFilters(!showSmallPageFilters)}>
                            {showSmallPageFilters ? "Hide" : "Show"} Filters
                        </button>
                    </>
                }
                {((!isTabletOrSmaller) || (isTabletOrSmaller && showSmallPageFilters)) &&
                    (
                    <Stack spacing="24px" direction={isTabletOrSmaller ? 'column' :'row'} className="filter-menu">
                        {/* Input group for search term */}        
                        <select id="location"
                            className="fitler-background"
                            onChange={() => PageFilters()}
                            defaultValue={locationStateValue}>
                            <option value="">All locations</option>
                            <option value="Maitland">Maitland</option>
                            <option value="East Maitland">East Maitland</option>
                            <option value="Rutherford">Rutherford</option>
                            <option value="Thornton">Thornton</option>
                        </select>
                        {/* Change how dates work with Page Size */}
                        {isTabletOrSmaller ?
                        <>
                            {/* Tablets/Phones and small screens */}
                            <Text className="fitler-date-label">Start</Text>
                            <InputGroup className="fitler-background">
                                <Input id="fromDate" type='date' defaultValue={fromStateValue}
                                    onChange={() => PageFilters()}/>
                            </InputGroup>   
                            <Text className="fitler-date-label">End</Text>
                            <InputGroup className="fitler-background">
                                <Input id="toDate" type='date' defaultValue={toStateValue}
                                    onChange={() => PageFilters()}/>  
                            </InputGroup>
                        </> :
                        <>
                            {/* Desktops and bigger screens */}
                            <Popover placement="bottom-start">
                                <PopoverTrigger>
                                    <button className="filter-menu-date-range">Date Range</button>
                                </PopoverTrigger>
                                <PopoverContent w="245px">
                                    <PopoverBody>
                                        <Stack direction="column">
                                            <Text className="fitler-date-label-big">From</Text>
                                            <InputGroup className="fitler-background">
                                                <Input id="fromDate" type='date' defaultValue={fromStateValue}
                                                    onChange={() => PageFilters()}/>
                                            </InputGroup>   
                                            <Text className="fitler-date-label-big">To</Text>
                                            <InputGroup className="fitler-background">
                                                <Input id="toDate" type='date' defaultValue={toStateValue}
                                                    onChange={() => PageFilters()}/>  
                                            </InputGroup>
                                        </Stack>
                                    </PopoverBody>
                                    </PopoverContent>
                            </Popover>
                        </>
                        }
                        {!isTabletOrSmaller &&
                            <Box width="100%"></Box>
                        }  
                        <InputGroup className="fitler-background">
                            <Input id="search" type="text" placeholder="Search events" 
                                defaultValue={searchStateValue}
                                onChange={() => PageFilters()} />                    
                            <InputRightElement>
                                <img src={SearchIcon} alt="Search Icon" />
                            </InputRightElement>
                        </InputGroup>
                        <button className="filter-button-clear" onClick={() => ClearFilters()}>
                            <Center>
                                <Text>Clear filters</Text>
                                <img src={CloseIcon} alt="Close Icon" />
                            </Center>
                        </button>                          
                    </Stack>
                )}
                {/* CONTENTS COMPONENT */}
                <Grid gap="2%" margin="2%" templateColumns={isTabletOrSmaller ? "repeat(1, 1fr)" : "repeat(6, 1fr)"} >
                    {eventData.map((allEventData, index) => {
                        if (index >= pageLength || index < (pageLength - 8)) {
                            return <></>;
                        }

                        return <EventBox
                            id={allEventData.id}
                            title={allEventData.title}
                            image={allEventData.image}
                            description={allEventData.description}
                            address={allEventData.address}
                            city={allEventData.city}
                            date={allEventData.date}
                            startTime={allEventData.startTime}
                            endTime={allEventData.endTime}
                            hyperlink={allEventData.hyperlink}
                            bookingLink={allEventData.bookingLink}
                            repeatType={allEventData.repeatType}
                            index={index + 1}
                            productTypeName={allEventData.productTypeName}
                        />                        

                    })}
                    <GridItem colSpan={isTabletOrSmaller ? 2 : 6}>
                        {/* PAGINATION COMPONENT */}
                        <Stack direction="row" justifyContent={isTabletOrSmaller ? "center" : "end"}>
                                {pageLength !== 8 ? 
                                        <button className="filter-button navigation-button"
                                            onClick={() => decrementPage()}>
                                                <Center>
                                                    <img src={ArrowIcon} className="flip-image" alt="Arrow Icon" width="22px"/>
                                                </Center>
                                            </button>
                                            : 
                                            <button className="filter-button navigation-button button-disabled">
                                                <Center>
                                                    <img src={ArrowIcon} className="flip-image" alt="Arrow Icon" width="22px"/>
                                                </Center>
                                            </button>
                                }  
                                {pages.map((item, index) => {
                                    return <Box key={item} id={"page-index-" + index} className={index === 0 ? "page-index page-index-active" : "page-index"}>{item}</Box>
                                })}     
                                {eventData.length === 8  ?                                 
                                    <button className="filter-button navigation-button button-disabled">
                                        <Center>
                                            <img src={ArrowIcon} alt="Arrow Icon" width="22px"/>
                                        </Center>
                                    </button> : 
                                    <>      
                                    {pageLength !<= eventData.length ?
                                        <button className="filter-button navigation-button"
                                            onClick={() => incrementPage()}>
                                                <Center>
                                                    <img src={ArrowIcon} alt="Arrow Icon" width="22px"/>
                                                </Center>
                                            </button>
                                            :
                                            <button className="filter-button navigation-button button-disabled">
                                                <Center>
                                                    <img src={ArrowIcon} alt="Arrow Icon" width="22px"/>
                                                </Center>
                                            </button>
                                    }</>
                                }
                        </Stack>
                    </GridItem>
                </Grid>
                
            </>
            }
        </>
    )
}
