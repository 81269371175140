import {
  ChakraProvider,
  Box,
  theme
} from "@chakra-ui/react"
import Content from "./Pages/Content"
import Event from "./Pages/Event";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./assets/styles/main.scss";
import FeaturedEvents from "./Pages/FeaturedEvents";

// Main page to route between sub-pages. Defaults with content page.
export const Main = () => (
  <ChakraProvider theme={theme}>
    <Box fontFamily="Open Sans" textAlign="center" fontSize="xl">
      <Router>
          <Routes>
              <Route path="/" element={<Content />} />
              <Route path="/event" element={<Event />} />
              <Route path="/featured-events" element={<FeaturedEvents />} />
              <Route path="/school-holidays-events" element={<Content productType={1184} />} />
              <Route path="/author-talks-events" element={<Content productType={1187} />} />
          </Routes>
      </Router>
    </Box>
  </ChakraProvider>
)
