export const LibraryProductTypes = [
    "Library",
    "Author Talk",
    "Book Launch",
    "Community Event",
    "Exhibition",
    "For Kids",
    "Lifelong Learning",
    "School Holidays",
    "Workshops"
];