import { IEventResponseModel, ISingleEventResponseModel } from "../models/library.models";

// Endpoint URLs
const eventBaseUrl = "https://maitlandapi.wpengine.com/wp-json/wp/v2/event";
const productTypeBaseUrl = "https://maitlandapi.wpengine.com/wp-json/wp/v2/product_type";

// Hyperlink Base URL for linking to events
const eventBaseHyperlink = "https://www.maitlandlibrary.com.au/whats-on/upcoming-events/";

// Call event endpoint
export function getEvents(props = null): Promise<IEventResponseModel[]> {

    // Create Product Type parameter. and set if there is an ID
    var productTypeParameter = "";

    if (props && props.productType && props.productType !== null) {
        productTypeParameter = `&product_type=${props.productType}`;
    }

    // Build endpoint request
    const request: RequestInfo = new Request(`${eventBaseUrl}?share_entity=1012&per_page=100${productTypeParameter}`, {
        method: 'GET'
    });

    // return the request
    return fetch(request)
        .then(response => response.json())
        .then(response => {            
            var results = {} as IEventResponseModel[];
            
            // Loop through results to get only necessary needed details
            response.forEach((item: any, index: number) => {
                // Split up all the extra date details
                var dateTime = item.ACF.start_date;
                var dateSplit = dateTime.split(" ");
                var finalDate = new Date(dateSplit[0]);

                // Work out if there are repeater dates and replace final date with them
                var dateNow = new Date();
                if (dateNow > finalDate) {
                    var repeatDates = item.ACF.system_repeater_dates;
                    
                    // Loop through repeat dates if any
                    for(let repeatDate in repeatDates) {
                        // Get the repeat dates and split it into the date 
                        var thisRepeatDate = item.ACF.system_repeater_dates[repeatDate];
                        var repeatDateSplit = thisRepeatDate.date.split(" ");
                        var finalRepeatDate = new Date(repeatDateSplit[0]);

                        if (finalRepeatDate >= dateNow) {
                            // Set final date to the repeast date and break out of the loop.
                            finalDate = finalRepeatDate;
                            break;
                        } 
                    };
                }
                
                // Remove the unecessary HTML in description
                var description = item.excerpt.rendered;
                var trimmedDescription = description.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("\n", "<br />");

                // Add result into a model
                var result: IEventResponseModel = {
                    id: item.id,
                    title: item.title.rendered,
                    image: item.fimg_url,
                    description: trimmedDescription,
                    address: item.ACF.location.address,
                    city: item.ACF.location.city,
                    date: finalDate,
                    startTime: item.ACF.start_time,
                    endTime: item.ACF.end_time,
                    hyperlink: `${eventBaseHyperlink}${item.slug}`,
                    bookingLink: item.ACF.ticket_booking_url,
                    productTypeId: item.product_type,
                    repeatType: item.ACF.repeat_type
                }

                // Add result in results model
                results[index] = result;
            });

            // return results
            return results as IEventResponseModel[];
        });        
}

// Call event endpoint
export function getSingleEvent(eventId): Promise<ISingleEventResponseModel> {

    // Build endpoint request
    const request: RequestInfo = new Request(`${eventBaseUrl}/${eventId}`, {
        method: 'GET'
    });

    // return the request
    return fetch(request)
        .then(response => response.json())
        .then(response => {       

            // Split up all the extra date details
            var dateTime = response.ACF.start_date;
            var dateSplit = dateTime.split(" ");
            var finalDate = new Date(dateSplit[0]);

            // Work out if there are repeater dates and replace final date with them
            var dateNow = new Date();
            if (dateNow > finalDate) {
                var repeatDates = response.ACF.system_repeater_dates;
                
                // Loop through repeat dates if any
                for(let repeatDate in repeatDates) {
                    // Get the repeat dates and split it into the date 
                    var thisRepeatDate = response.ACF.system_repeater_dates[repeatDate];
                    var repeatDateSplit = thisRepeatDate.date.split(" ");
                    var finalRepeatDate = new Date(repeatDateSplit[0]);

                    if (finalRepeatDate >= dateNow) {
                        // Set final date to the repeast date and break out of the loop.
                        finalDate = finalRepeatDate;
                        break;
                    } 
                };
            }
            
            // Remove the unecessary HTML in description
            var description = response.content.rendered;
            var trimmedDescription = description.replaceAll("\n", "").replaceAll("<p>", "<p style=\"padding-bottom:10px\">");

            // Remove the unecessary HTML in description
            var excerpt = response.excerpt.rendered;
            var trimmedExcerpt = excerpt.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("\n", "<br />");

            // Add result into a model
            var results: ISingleEventResponseModel = {
                id: response.id,
                title: response.title.rendered,
                image: response.fimg_url,
                excerpt: trimmedExcerpt,
                description: trimmedDescription,
                address_name: response.ACF.location.name,
                street_name: response.ACF.location.street_name,
                street_number: response.ACF.location.street_number,
                address: response.ACF.location.address,
                city: response.ACF.location.city,
                date: finalDate,
                contact_phone: response.ACF.contact_details.phone,
                contact_email: response.ACF.contact_details.email,
                startTime: response.ACF.start_time,
                endTime: response.ACF.end_time,
                hyperlink: `${eventBaseHyperlink}${response.slug}`,
                bookingLink: response.ACF.ticket_booking_url,
                repeatDates: response.ACF.include_dates,
                systemRepeatDates: response.ACF.system_repeater_dates,
                images: response.ACF.images,
                free_entry: response.ACF.features.free_entry,
                price_from: response.ACF.price_from
            }

            // return results
            return results as ISingleEventResponseModel;
        });        
}

// Call event endpoint
export function getProductType(productTypeId): Promise<string> {

    // Build endpoint request
    const request: RequestInfo = new Request(`${productTypeBaseUrl}/${productTypeId}`, {
        method: 'GET'
    });

    // return the request
    return fetch(request)
        .then(response => response.json())
        .then(response => {       
            var result = response.name;        

            // return results
            return result;
        });        
}