import { Grid} from "@chakra-ui/react"
import { FeaturedEvent } from "../Components/EventBox";
import { getEvents, getProductType } from "../apis/library.api";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { LibraryProductTypes } from "../assets/constants";

// All event data array for featured events
var allEventData = [];

export default function FeaturedEvents() {

    const [eventData, setEventData] = useState(allEventData);
    const [isLoading, setIsLoading] = useState(true);

    // Define size for tablets or smaller
    const isTabletOrSmaller = useMediaQuery({
        query: "(max-width: 800px)"
    });

    useEffect(() => {
        const runGetEvents = async () => {
            const response = await getEvents();
            
            for(var item in response) {
                // Check events are future or today and add to the all event list.
                var dateCheck = new Date();
                dateCheck.setDate(dateCheck.getDate() - 1);

                if (response[item].date >= dateCheck) {   
                    
                    // Set Product Type for each event
                    var productTypes = [];
                    
                    // Loop through product types and get product names from API
                    for (const index in response[item].productTypeId) {
                        var productType = await getProductType(response[item].productTypeId[index]);
                        if (LibraryProductTypes.indexOf(productType) > -1) {
                            productTypes.push(productType);
                        }
                    }

                    response[item].productTypeName = productTypes;
                    
                    allEventData.push(response[item]);
                }
            }

            // Sort the even data from most recent first
            allEventData = [].concat(allEventData)
                .sort((a, b) => a.date > b.date ? 1 : -1);

            // Get only the first 2 elements as we only want 2 to feature.
            allEventData = allEventData.slice(0,2);

            setEventData(allEventData);
            setIsLoading(false);
        }
        

        // Run Events or stop loading
        if (allEventData.length === 0) {
            runGetEvents();
        } else {
            if (isLoading === true) {
                setIsLoading(false);
            }
        }

    // eslint-disable-next-line
    }, []);

    return (
        <>
            {/* CONTENTS COMPONENT */}
            <Grid gap="2%" margin="2%" templateColumns={isTabletOrSmaller ? "repeat(1, 1fr)" : "repeat(6, 1fr)"} >
                    {eventData.map((allEventData, index) => {
                        return <FeaturedEvent
                            id={allEventData.id}
                            title={allEventData.title}
                            image={allEventData.image}
                            description={allEventData.description}
                            address={allEventData.address}
                            city={allEventData.city}
                            date={allEventData.date}
                            startTime={allEventData.startTime}
                            endTime={allEventData.endTime}
                            hyperlink={allEventData.hyperlink}
                            bookingLink={allEventData.bookingLink}
                            repeatType={allEventData.repeatType}
                            index={index + 1}
                            productTypeName={allEventData.productTypeName}
                        />                       

                    })}
                </Grid>
        </>
    );

}