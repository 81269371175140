import * as ReactDOM from "react-dom/client"
import { Main } from "./Main"


const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

root.render(
  <>
    <Main />
  </>
)
