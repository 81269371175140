import { Box, Text, Stack } from "@chakra-ui/react";
import { useState } from "react";
import WheelChairIcon from "../assets/images/icons/Icon-Small-Grey-Mobility access.svg";
import WifiIcon from "../assets/images/icons/Icon-Small-Grey-Wifi.svg";
import CoffeeIcon from "../assets/images/icons/Icon-Small-Grey-Cafe.svg";
import ParkingIcon from "../assets/images/icons/Icon-Small-Grey-Parking.svg";
import BookingIcon from "../assets/images/icons/Icon-Small-Grey-Room booking.svg";
import ComputerIcon from "../assets/images/icons/Icon-Small-Grey-Computer access.svg";

/*
* These components are used to display information at event locations.
* They consist of a chiplet design and a description that will display on hover.
*/

// Mobility access
export function MobilityAccess() {

    var description = "All our locations have access ramps for our visitors";

    return (
        <StandardChiplet text="Mobility access" icon={<img src={WheelChairIcon} alt="wheel chair icon" />} description={description}/>
    )
}

// Cafe
export function Cafe() {
    var description = "Enjoy a coffee and cake at our Readers Café and Larder.";

    return (
        <StandardChiplet text="Café" icon={<img src={CoffeeIcon} alt="Coffee Icon" />} description={description}/>
    )
}

// Free wi-fi
export function FreeWifi() {
    var description = "Enjoy free Wi-Fi at all our locations.";

    return (
        <StandardChiplet text="Free Wi-Fi" icon={<img src={WifiIcon} alt="Wifi Icon" />} description={description}/>
    )
}

// Car parking
export function CarParking() {
    var description = "Free library parking at Maitland and East Maitland.";

    return (
        <StandardChiplet text="Car parking" icon={<img src={ParkingIcon} alt="Parking Icon" />} description={description}/>
    )
}

// Computers
export function Computers() {
    var description = "Gain access to free computers, printing and more at all locations.";

    return (
        <StandardChiplet text="Computers" icon={<img src={ComputerIcon} alt="Computer Icon" />} description={description}/>
    )
}

// Room bookings
export function RoomBookings() {
    var description = "Spaces available to book at Maitland, East Maitland and Rutherford.";

    return (
        <StandardChiplet text="Room bookings" icon={<img src={BookingIcon} alt="Booking Icon" />} description={description}/>
    )
}

// The standard chiplet design with props to provide consitency.
function StandardChiplet({text, icon, description}) {

    const [showInformation, setShowInformation] = useState("none");

    function displayInformation() {
        setShowInformation("block");
    }

    function removeInformation() {
        setShowInformation("none");
    }

    return (
        <Stack direction="column" display="inline-block">
            <Box className="chiplets" onMouseOver={() => displayInformation()}
                onMouseOut={() => removeInformation()}>
                <Stack direction="row">
                    <Box>
                        {icon}
                    </Box>
                    <Text paddingTop="3px">{text}</Text>
                </Stack>
            </Box>
            <Box className="chipletInformation" display={showInformation}>
                <Text>{description}</Text>
            </Box>
        </Stack>
    );
}