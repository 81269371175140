import { Box, Link, Text, Image, GridItem, Stack } from "@chakra-ui/react"
import { IEventResponseModel } from "../models/library.models";
import CalendarIcon from "../assets/images/icons/Icon-Custom-Small-Grey-Calendar.svg";
import TicketIcon from "../assets/images/icons/Icon-Small-Grey-Ticket.svg";
import LocationIcon from "../assets/images/icons/Icon-Custom-Small-Grey-Location.svg";
import BookIcon from "../assets/images/icons/Icon-Small-Grey-Weekly tag.svg";
import MonthlyIcon from "../assets/images/icons/Icon-Small-Grey-Monthly tag.svg";
import ArrowIcon from "../assets/images/icons/Icon-Medium-Grey-Arrow-right.svg";

/* 
* A Default template for an event display box
* This component is used for displaying event cards within the content pages.
*/
export default function EventBox(props: IEventResponseModel) {

    // Parse and format the date for event into easy readability
    var parsedDate = new Date(props.date);
    var dayName = parsedDate.toLocaleDateString("en-AU", { weekday: 'short'});
    var monthName = parsedDate.toLocaleDateString("en-AU", { month: 'short'});

    return (
        <GridItem colSpan={((props.index + 1) % 5 === 0 || props.index % 5 === 0) ? 3 : 2 } 
            className={((props.index + 1) % 5 === 0 || props.index % 5 === 0) ? "event-box-container" : "event-box-container-small" }>
            <Link href={props.hyperlink} isExternal _hover={{textDecoration: "none"}}>
                <Image src={props.image} alt={props.image} className={((props.index + 1) % 5 === 0 || props.index % 5 === 0) ? "event-box-image" : "event-box-image-small" } />
                {props.bookingLink && <BookingInfoBadge />}
                {!props.bookingLink && props.repeatType !== "none" && <InfoBadge repeatType={props.repeatType}/>}
                <Box className="event-box-information-container">
                    <Text className="event-box-information-product-type">{props.productTypeName.join(", ")}</Text>
                    <Text className="event-box-information-title event-box-margin-top-12 " dangerouslySetInnerHTML={{__html: props.title}}></Text>
                    <Stack direction="row" className="event-box-margin-top-12">
                        <img src={CalendarIcon} alt="Calendar Icon" /> 
                        <Text className="event-box-information-location">{dayName} {parsedDate.getDate()} {monthName} 
                            {props.startTime && <> at {props.startTime.replace('.00', '').replace(' ','')}</>}</Text>
                    </Stack>
                    <Stack direction="row" className="event-box-margin-top-12">
                        <img src={LocationIcon} alt="Location Icon" />
                        <Text className="event-box-information-location">{props.city}</Text>
                    </Stack>
                    <Text className="event-box-information-description event-box-margin-top-12" dangerouslySetInnerHTML={props.description.length > 75 ?
                        {__html: props.description.substring(0,75) + "&hellip;"} : {__html: props.description.substring(0,75)}}></Text><br />
                    <Stack direction="row" className="event-box-actions-container">
                        <Link href={props.hyperlink} isExternal>
                            <img src={ArrowIcon} alt="Arrow Icon" width="22px"/>
                        </Link>
                    </Stack>
                </Box>
            </Link>
        </GridItem>
    )
}

/*
* This component is used for the Featured Events page.
*/
export function FeaturedEvent(props: IEventResponseModel) {
    // Parse and format the date for event into easy readability
    var parsedDate = new Date(props.date);
    var dayName = parsedDate.toLocaleDateString("en-AU", { weekday: 'short'});
    var monthName = parsedDate.toLocaleDateString("en-AU", { month: 'short'});

    return (
        <GridItem colSpan={3} className="event-box-container">
            <Link href={props.hyperlink} isExternal _hover={{textDecoration: "none"}}>
                <Image src={props.image} alt={props.image} className="event-box-image" />
                {props.bookingLink && <BookingInfoBadge />}
                {!props.bookingLink && props.repeatType !== "none" && <InfoBadge repeatType={props.repeatType}/>}
                <Box className="event-box-information-container">
                    <Text className="event-box-information-product-type">{props.productTypeName.join(", ")}</Text>
                    <Text className="event-box-information-title event-box-margin-top-12 " dangerouslySetInnerHTML={{__html: props.title}}></Text>
                    <Stack direction="row" className="event-box-margin-top-12">
                        <img src={CalendarIcon} alt="Calendar Icon" /> 
                        <Text className="event-box-information-location">{dayName} {parsedDate.getDate()} {monthName} 
                            {props.startTime && <> at {props.startTime.replace('.00', '').replace(' ','')}</>}</Text>
                    </Stack>
                    <Stack direction="row" className="event-box-margin-top-12">
                        <img src={LocationIcon} alt="Location Icon" />
                        <Text className="event-box-information-location">{props.city}</Text>
                    </Stack>
                    <Text className="event-box-information-description event-box-margin-top-12" dangerouslySetInnerHTML={props.description.length > 75 ?
                        {__html: props.description.substring(0,75) + "&hellip;"} : {__html: props.description.substring(0,75)}}></Text><br />
                    <Stack direction="row" className="event-box-actions-container">
                        <Link href={props.hyperlink} isExternal>
                            <img src={ArrowIcon} alt="Arrow Icon" width="22px"/>
                        </Link>
                    </Stack>
                </Box>
            </Link>
        </GridItem>
    )
}

/* 
* Booking information badge component.
* This component is used ticketed events.
*/
function BookingInfoBadge() {

    return(
        <Box className="event-box-badge-container">
            <Box backgroundColor="#FFEAC6" className="event-box-badge">
                <Stack direction="row">
                    <img src={TicketIcon} alt="Ticket Icon" />
                    <Text className="event-box-badge-text">Ticketed&nbsp;</Text>
                </Stack>
            </Box>
        </Box>
    );
}

/* 
* Information badge component.
* This component is used weekly and monthly re-occuring events.
*/
function InfoBadge({repeatType}) {
    return(
        <Box className="event-box-badge-container">
            <Box backgroundColor={repeatType === "week" ? "#FFDBFB" : "#D9F1FB"} className="event-box-badge">
                <Stack direction="row">
                    {repeatType === "week" ?
                        <img src={BookIcon} alt="Book Icon" />
                    :   <img src={MonthlyIcon} alt="Book Icon" />
                    }
                    <Text className="event-box-badge-text">{repeatType === "week" ? "Weekly" : "Monthly"} &nbsp;</Text>
                </Stack>
            </Box>
        </Box>
    );
}