import { Text, Spinner, Image, Box, Grid, GridItem, Divider, Stack, Link, Center, Wrap, WrapItem } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getSingleEvent } from "../apis/library.api";
import { ISingleEventResponseModel } from "../models/library.models";
import { MobilityAccess, Cafe, FreeWifi, CarParking, Computers, RoomBookings } from "../Components/Chiplets";
import CalendarIcon from "../assets/images/icons/Icon-Custom-Small-Grey-Calendar.svg";
import MailIcon from "../assets/images/icons/Icon-Small-Grey-Letter.svg";
import TicketIcon from "../assets/images/icons/Icon-Small-Grey-Ticket.svg";
import ArrowIcon from "../assets/images/icons/Icon-Small-Beige-Arrow.svg";
import LocationIcon from "../assets/images/icons/Icon-Small-Grey-Location.svg";
import PhoneIcon from "../assets/images/icons/Icon-Small-Grey-Contact number.svg";
import ClockIcon from "../assets/images/icons/Icon-Custom-Small-Grey-Clock.svg";
import BlackArrowIcon from "../assets/images/icons/Icon-Small-Grey-Arrow-Up right.svg";
import RightIcon from "../assets/images/icons/Icon-Medium-Grey-Arrow-right.svg";
import { useMediaQuery } from "react-responsive";

/*
* This component is used to display a single page of the event.
* Several components are used below to display information from styled locations.
*/

// Default page render
export default function Event() {
    
    var eventDetails: ISingleEventResponseModel = {}
    
    const [isLoading, setIsLoading] = useState(true);
    const [singleEventDetails, setSingleEventDetails] = useState(eventDetails);

    // Define size for tablets or smaller
    const isTabletOrSmaller = useMediaQuery({
        query: "(max-width: 1000px)"
    });

    var getOriginSearch = window.location.search;
    var id = getOriginSearch.replace("?id=", "");

    useEffect(() => {
        const runGetSingleEvent = async () => {
            const response = await getSingleEvent(id);

            // eslint-disable-next-line
            eventDetails = response;

            if (eventDetails.repeatDates) {

                // Filter dates for future
                eventDetails.repeatDates = eventDetails.repeatDates.filter(item => new Date(item.start_date) > eventDetails.date);

                eventDetails.repeatDates.forEach(item => {
                    // Format the start date.
                    var splitStartDate = item.start_date.split(" ");
                    var parsedStartDate = new Date(splitStartDate[0]);
                    var formattedStartDate = parsedStartDate.toLocaleDateString("en-AU", { weekday: 'long'}) + " " + parsedStartDate.getDate() + 
                        " " + parsedStartDate.toLocaleDateString("en-AU", { month: 'short'});
                    item.start_date = formattedStartDate;

                    // Format the end date
                    var splitEndDate = item.end_date.split(" ");
                    var parsedEndDate = new Date(splitEndDate[0]);
                    var formattedEndDate = parsedEndDate.toLocaleDateString("en-AU", { weekday: 'long'}) + " " + parsedEndDate.getDate() + 
                        " " + parsedEndDate.toLocaleDateString("en-AU", { month: 'short'});
                    item.end_date = formattedEndDate;
                });
            }

            if (!eventDetails.repeatDates && eventDetails.systemRepeatDates) {                
                eventDetails.systemRepeatDates = eventDetails.systemRepeatDates.filter(item => new Date(item.date.split(" ")[0]) > eventDetails.date);

                eventDetails.systemRepeatDates.forEach(item => {
                    // Format the start date.
                    var splitStartDate = item.date.split(" ");
                    var parsedStartDate = new Date(splitStartDate[0]);
                    var formattedStartDate = parsedStartDate.toLocaleDateString("en-AU", { weekday: 'long'}) + " " + parsedStartDate.getDate() + 
                        " " + parsedStartDate.toLocaleDateString("en-AU", { month: 'short'});
                    item.date = formattedStartDate;
                });

                if (eventDetails.systemRepeatDates.length <= 0) {
                    eventDetails.systemRepeatDates = null;
                }
            }

            if (eventDetails.images) {
                eventDetails.images.forEach((img, index) => {
                    if (!img.url) {
                        eventDetails.images.splice(index, 1);
                    }
                });
            }

            setSingleEventDetails(response);
            setIsLoading(false);
        }
        
        if (Object.keys(eventDetails).length === 0) {
            runGetSingleEvent();
        } else {
            if (isLoading === true) {
                setIsLoading(false);
            }
        }

    // eslint-disable-next-line
    }, []);

    // Parse and format the date for event into easy readability
    var parsedDate = new Date(singleEventDetails.date);
    var dayName = parsedDate.toLocaleDateString("en-AU", { weekday: 'long'});
    var monthName = parsedDate.toLocaleDateString("en-AU", { month: 'short'});
    var addressHyperlink = `https://maps.google.com/?q=${singleEventDetails.street_number} ${singleEventDetails.street_name} ${singleEventDetails.city}`;
    if (!singleEventDetails.street_number) {
        addressHyperlink = `https://maps.google.com/?q=${singleEventDetails.address}`
    }

    return(<>
        {isLoading && <>
            <Text>Loading... <Spinner /></Text>
        </>}
        {!isLoading &&
            <Box className="event-container">
                <Box className="event-image-container">
                    <Image className="event-image" src={singleEventDetails.image} alt={singleEventDetails.image} />
                </Box>
                <Box marginLeft="5px" marginRight="5px" marginTop="15px" textAlign="left">
                    <br />
                    <Grid templateColumns={isTabletOrSmaller ? 'repeat(1, 1fr)': 'repeat(2, 1fr)'} gap={isTabletOrSmaller ? 2 : 0}>
                        <GridItem>                            
                            <Box className="branch-information">
                                <Text className="branch-heading">About {singleEventDetails.city} branch</Text>
                                <Wrap direction="row" gap={3} marginTop="18px">
                                    {/* Add common branch chiplets */}
                                    <WrapItem>
                                        <MobilityAccess />
                                    </WrapItem>
                                    <WrapItem>
                                        <FreeWifi />
                                    </WrapItem>
                                    {/* Add individual branch chiplets */}
                                    {singleEventDetails.city === "Maitland" &&
                                    <>
                                        <WrapItem>
                                            <CarParking />
                                        </WrapItem>
                                        <WrapItem>
                                            <RoomBookings />
                                        </WrapItem>
                                    </>}
                                    {singleEventDetails.city === "East Maitland" &&
                                    <>
                                        <WrapItem>
                                            <CarParking />
                                        </WrapItem>
                                        <WrapItem>
                                            <Cafe />
                                        </WrapItem>
                                    </>}
                                    {singleEventDetails.city === "Thornton" &&
                                    <>
                                        <WrapItem>
                                            <Computers />
                                        </WrapItem>
                                    </>}
                                    {singleEventDetails.city === "Rutherford" &&
                                    <>
                                        <WrapItem>
                                            <Computers />
                                        </WrapItem>
                                        <WrapItem>
                                            <RoomBookings />
                                        </WrapItem>
                                    </>}
                                </Wrap>
                            </Box>       
                            {/* Display even description in this block if on desktop */}
                            {!isTabletOrSmaller && <EventDescription />}             
                        </GridItem>
                        <GridItem>
                            <Box className="event-cta">
                                <Text fontSize={isTabletOrSmaller ? "18px" : "24px"} fontWeight="semibold">At {singleEventDetails.address_name}</Text>
                                <Divider marginTop="24px" marginBottom="24px" />
                                <Stack direction="row">
                                    <img src={CalendarIcon} alt="Calendar Icon" /> <Text className="event-details">{dayName} {parsedDate.getDate()} {monthName} 
                                        {singleEventDetails.startTime && <> at {singleEventDetails.startTime.replace('.00', '').replace(' ','')}</>}</Text>
                                </Stack>
                                <Divider marginTop="24px" marginBottom="24px" />
                                <Link href={`${addressHyperlink}`} 
                                    target="_blank" className="event-bold-underline">
                                    <Stack direction="row">
                                        <img src={LocationIcon} alt="Location icon" />
                                        <Text className="event-details">
                                            {singleEventDetails.street_number ? 
                                                `${singleEventDetails.street_number} ${singleEventDetails.street_name}, ${singleEventDetails.city}` :
                                                `${singleEventDetails.address}`
                                            }
                                        </Text>
                                        <img src={BlackArrowIcon} alt="Arrow Icon" />
                                    </Stack>
                                </Link>
                                {singleEventDetails.contact_phone && <>
                                    <Link href={`tel:${singleEventDetails.contact_phone}`} className="event-bold-underline">
                                        <Stack direction="row" className="event-margin-divide">
                                            <img src={PhoneIcon} alt="Phone icon" />
                                            <Text className="event-details">{singleEventDetails.contact_phone}</Text>
                                            <img src={BlackArrowIcon} alt="Arrow Icon" />
                                        </Stack>
                                    </Link>
                                </>}
                                {singleEventDetails.contact_email && <>
                                    <Link href={`mailto:${singleEventDetails.contact_email}`} className="event-bold-underline">
                                        <Stack direction="row" className="event-margin-divide">
                                            <img src={MailIcon} alt="Mail Icon" />
                                            <Text className="event-details" title={singleEventDetails.contact_email}>Email event organiser</Text>
                                            <img src={BlackArrowIcon} alt="Arrow Icon" />
                                        </Stack>
                                    </Link>
                                </>}
                                {singleEventDetails.bookingLink && <>                                
                                    <Stack direction="row" className="event-margin-divide">
                                    <img src={TicketIcon} alt="Ticket Icon" /><Text className="event-details">{singleEventDetails.free_entry ? "Free event" : `Paid Event - Starting at $${singleEventDetails.price_from}`}</Text>
                                    </Stack> 
                                    <Link href={singleEventDetails.bookingLink} isExternal color="white" className="primaryButton event-margin-divide secure-button"
                                        _hover={{textDecoration: "none"}}>
                                        <Stack direction="row" margin="auto">
                                            <Center><Text>Book your spot</Text><img src={ArrowIcon} alt="Arrow Icon" /></Center>
                                        </Stack>           
                                    </Link>
                                </>}
                                {!singleEventDetails.bookingLink && singleEventDetails.free_entry && <>
                                    <Stack direction="row" className="event-margin-divide">
                                    <img src={TicketIcon} alt="Ticket Icon" /><Text className="event-details">Free event</Text>
                                    </Stack> 
                                </>
                                }
                            </Box>
                            {/* Display even description in this block if on tablet or smaller */}
                            {isTabletOrSmaller && <EventDescription />}  
                        </GridItem>
                        {/* Use when repeat dates are used*/}
                        {singleEventDetails.repeatDates && <>
                            <GridItem>                            
                                <Box backgroundColor="white" borderRadius="5px" boxShadow="0px 0px 5px lightgray"
                                    paddingTop="15px" paddingBottom="15px" paddingLeft="20px"
                                    borderLeft="solid black 10px">
                                    <Text fontSize="xl" fontWeight="semibold">Other dates at {singleEventDetails.address_name}</Text>
                                    {singleEventDetails.repeatDates.map(item => {
                                        return <Stack direction="row" marginTop="15px">
                                            <img src={CalendarIcon} alt="Calendar Icon" /><Text marginTop="-2px" fontSize="lg">{item.start_date}</Text>
                                            <img src={ClockIcon} alt="Clock icon" /><Text marginTop="-2px" fontSize="lg">{item.start_time.replace('.00', '').replace(' ','')} - {item.end_time.replace('.00', '').replace(' ','')}</Text>
                                        </Stack>
                                    })}
                                </Box>
                            </GridItem>
                        </>}
                        {/* Use when system dates are used instead of repeat dates */}
                        {!singleEventDetails.repeatDates && singleEventDetails.systemRepeatDates && <>
                            <GridItem>                            
                                <Box backgroundColor="white" borderRadius="5px" boxShadow="0px 0px 5px lightgray"
                                    paddingTop="15px" paddingBottom="15px" paddingLeft="20px"
                                    borderLeft="solid black 10px">
                                    <Text fontSize="xl" fontWeight="semibold">Other dates at {singleEventDetails.address_name}</Text>
                                    {singleEventDetails.systemRepeatDates.map(item => {
                                        return <Stack direction="row" marginTop="15px">
                                            <img src={CalendarIcon} alt="Calendar Icon" /><Text marginTop="-2px" fontSize="lg">{item.date}</Text>
                                            <img src={ClockIcon} alt="Clock icon" /><Text marginTop="-2px" fontSize="lg">{singleEventDetails.startTime && <>{singleEventDetails.startTime.replace('.00', '').replace(' ','')}</>}</Text>
                                        </Stack>
                                    })}
                                </Box>
                            </GridItem>
                        </>}
                        {singleEventDetails.images.length > 0 && <>
                            <GridItem colSpan={!isTabletOrSmaller ? 2 : 1}>
                                <EventGallery images={singleEventDetails.images} />
                            </GridItem>
                        </>}
                    </Grid>
                </Box>
            </Box>
        }
        </>
    );

    // Description of the event, including exerpt and description from API.
    function EventDescription() {
        return(
            <Stack className="event-description-container" direction="column">
                {singleEventDetails.excerpt && 
                    <Text className="event-excerpt" dangerouslySetInnerHTML={{__html: singleEventDetails.excerpt}}></Text>
                }
                <Text className="event-description" dangerouslySetInnerHTML={{__html: singleEventDetails.description}}></Text>
            </Stack>
        );
    }

    // Image event gallery
    function EventGallery(props) {

        const [imageIndex, setImageIndex] = useState(0); 
        const imageGalleryLength = props.images.length;

        function incrementImage() {
            // Get all necessary elements
            var eventImage = document.getElementById("event-gallery-image") as HTMLInputElement;
            var eventCaption = document.getElementById("event-gallery-caption") as HTMLInputElement;
            var eventCurrentImage = document.getElementById(`event-gallery-${imageIndex}`) as HTMLInputElement;
            var eventSelectedImage = document.getElementById(`event-gallery-${imageIndex + 1}`) as HTMLInputElement;
            
            // Manipulate elements
            eventImage.src = props.images[imageIndex + 1].url;
            eventCaption.innerHTML = props.images[imageIndex + 1].caption;
            eventCurrentImage.classList.remove("event-gallery-actions-item-active");
            eventSelectedImage.classList.add("event-gallery-actions-item-active");

            setImageIndex(imageIndex + 1);
        }

        function decrementImage() {
            // Get all necessary elements
            var eventImage = document.getElementById("event-gallery-image") as HTMLInputElement;
            var eventCaption = document.getElementById("event-gallery-caption") as HTMLInputElement;
            var eventCurrentImage = document.getElementById(`event-gallery-${imageIndex}`) as HTMLInputElement;
            var eventSelectedImage = document.getElementById(`event-gallery-${imageIndex - 1}`) as HTMLInputElement;

            // Manipulate elements
            eventImage.src = props.images[imageIndex - 1].url;
            eventCaption.innerHTML = props.images[imageIndex - 1].caption;            
            eventCurrentImage.classList.remove("event-gallery-actions-item-active");
            eventSelectedImage.classList.add("event-gallery-actions-item-active");

            setImageIndex(imageIndex - 1);
        }


        return(
            <>
                <Box className="event-gallery-container">
                    <Image id="event-gallery-image" src={props.images[0].url} alt="event image" />
                    <Text id="event-gallery-caption">{props.images[0].caption}</Text>  
                    <Stack direction="row" spacing={5} className="event-gallery-actions-container">                  
                        {imageIndex > 0 ? 
                            <button className="event-gallery-actions event-gallery-actions-left" onClick={() => decrementImage()}><img src={RightIcon} alt="Right Icon" /></button>
                            :
                            <button className="event-gallery-actions event-gallery-actions-left"><img src={RightIcon} alt="Right Icon" /></button>
                        }
                        <Box className="event-gallery-actions-center">
                            <Center>
                                <Stack direction="row" spacing={1}>
                                    {props.images.map((item, index) => {
                                        return <Box key={item.caption} id={"event-gallery-" + index} className={index === 0 ? "event-gallery-actions-item event-gallery-actions-item-active" : "event-gallery-actions-item"}></Box>
                                    })}
                                </Stack>
                            </Center>
                        </Box>
                        {imageIndex < imageGalleryLength - 1 ?
                            <button className="event-gallery-actions" onClick={() => incrementImage()}><img src={RightIcon} alt="Right Icon" /></button>
                            :
                            <button className="event-gallery-actions"><img src={RightIcon} alt="Right Icon" /></button>
                        }
                    </Stack>
                </Box>
            </>
        );
    }
}